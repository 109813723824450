@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "FC Active";
    src: local("FC Active"),
    url("./fonts/FC-Active/FC-Active-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "FC Active Light";
    src: local("FC Active Light"),
    url("./fonts/FC-Active/FC-Active-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: "FC Active Italic";
    src: local("FC Active Italic"),
    url("./fonts/FC-Active/FC-Active-Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "FC Active Light Italic";
    src: local("FC Active Light Italic"),
    url("./fonts/FC-Active/FC-Active-Light-Italic.ttf") format("truetype");
  }
}

body {
  font-family: "FC Active", serif;
  /*font-size: 16px;*/
  /*color: white;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
