body {
  color: white;
}

/* SweetAlert2 */
.swal2-title {
  font-family: 'FC Active', sans-serif;
  color: white !important;
}

.swal2-content {
  font-family: 'FC Active', sans-serif;
  color: white !important;
}

.online-user-toast {
  top: 36rem;
}

.custom-toast {
  border-color: #ffca00;
  border-width: 2px;
}

.online-user-icon-animate {
  animation: glowing-green 2s ease-in-out infinite;
}
.online-user-text-animate {
  animation: glowing-white 2s ease-in-out infinite;
}

@keyframes glowing-green {
  0% {
    color: #D0A236;
    box-shadow: 0 0 10px #D0A236;
  }

  50% {
    color: #e6b74b;
    box-shadow: 0 0 20px #e6b74b, 0 0 30px #e6b74b, 0 0 40px #e6b74b;
  }

  100% {
    color: #D0A236;
    box-shadow: 0 0 10px #D0A236;
  }
}

@keyframes glowing-white {
  0% {
    color: rgba(255, 255, 255, 0.758);
    text-shadow: 0 0 10px #fff;
  }

  50% {
    color: white;
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff;
  }

  100% {
    color: rgba(255, 255, 255, 0.758);
    text-shadow: 0 0 10px #fff;
  }
}
